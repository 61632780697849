import assign from 'es6-object-assign';
import promise from 'es6-promise';
import find from './services/polyfill/find';
import findIndex from './services/polyfill/findIndex';
import isNaN from './services/polyfill/isNaN';
import 'array.prototype.fill';
import includes from 'array-includes';
import padStart from 'string.prototype.padstart';
import React, {Suspense, useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Api from 'tide-api';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/fonts-def.scss';
import './assets/styles/App.scss';
import {LOGIN_STATE} from "tide-api";
import store from "./services/redux/store";
import {useSelector} from "react-redux";
import Splash from "./components/Splash";
import getMainRoutes from "./services/routes/mainRoutes";
import LoadingTopBar from "./components/LoadingTopBar/LoadingTopBar";
import {notifierRef} from "./services/notifier";
import TideToaster from "./components/TideToaster/TideToaster";
import 'moment/locale/es';
import {customizeMoment} from "./services/dateUtils";
import loginApiConfig from "./services/api/login-api-config";

customizeMoment();

const api = new Api({...loginApiConfig, reduxStore: store});

function App() {

    /* ----- Polyfills to be compatible with older explorers ----- */

    if (!Object.assign)
        assign.polyfill();

    if (typeof Promise === "undefined") {
        promise.polyfill();
    }

    find.shim();
    findIndex.shim();
    isNaN.shim();
    includes.shim();
    padStart.shim();

    const loggedIn = useSelector(({api}) => api.loggedIn === LOGIN_STATE.LOGGED_IN);

    useEffect(()=>{
       if(!loggedIn) {
           api.token = null;
       }
    }, [loggedIn])

    const loading = useSelector(({loadingIds}) => !!loadingIds['Initializing.me']);
    const me = useSelector(({api}) => api.me);

    useEffect(() => {
        if (!me && !loading && loggedIn)
            api.me.get({loadingId: 'Initializing.me'}).catch(() => api.logout());
    }, [me, loading, loggedIn]);


    const routes = getMainRoutes(loggedIn);

    const splash = loading || (loggedIn && !me);


    return (
        <div className="App">

            <LoadingTopBar/>
            <TideToaster ref={notifierRef}/>

                    <Router>
                        <Suspense fallback={<Splash/>}>

                        {splash ?
                            <Splash/>
                            :
                            <Switch>
                                {routes.map(route =>
                                    <Route key={route.path} path={route.path} component={route.component}
                                           exact={route.exact === true}/>
                                )}
                                <Redirect from='/' to={routes[0].path}/>
                            </Switch>
                        }
                        </Suspense>
                    </Router>
        </div>
    );
}

export default App;
