import React from 'react';
import './LoadingTopBar.scss';
import {useSelector} from "react-redux";

const LoadingTopBar = () => {

    const loading = useSelector(({loading})=>!!loading);

    if(!loading)
        return null;

    return (
        <div className={"LoadingTopBar"} />
    );
};

export default LoadingTopBar;
