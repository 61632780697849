import React from 'react';
import semver from 'semver-lite';
import packageInfo from '../../pckg';
import {getNotifier} from "../notifier";


const config = {
    host: window.location.origin,
    commonPath: 'api',
    credentials: 'include',
    getDataFromResponse: handleResponse,
    getMetaDataFromResponse: (r) => r.meta,
    onError: errorHandler,
    saveTokenToLocalStorage: true,
    useSessionStorage: true,
    endpoints: [
        'me',
        {
            name: 'users',
            customMethods: {
                verifyCredentials: function ({username, password, ...conf}) {
                    return this.apiCall('/apa/verify_credentials', "verify_credentials", {
                        username,
                        password
                    }, {method: "POST", useCommonPath: false, ...conf})
                },
                changePasswordPublic: function ({username, newPassword, oldPassword, otp, ...conf}) {
                    return this.apiCall('/apa/change_password', "verify_credentials", {
                        username,
                        oldPassword,
                        newPassword,
                        otp
                    }, {method: "POST", useCommonPath: false, ...conf})
                }
            }
        },
    ],
    login: {
        createBody: (username, password, otp) => {
            return JSON.stringify({"username": username, "password": password, "otp": otp});
        },
        getHeaders: () => {
            return {
                'Content-Type': 'application/json'
            }
        }
    },
    logout: {
        path: '/apa/logout',
        useCommonPath: false
    },
};


function handleResponse(response, headers) {

    try {
        let server = headers.get("X-App-Version");
        let force = !!server && server[server.length - 1] === 'f';
        if (force) server = server.slice(0, server.length - 1);

        if (semver.validate(packageInfo.version) && semver.validate(packageInfo.version) && semver.gt(server, packageInfo.version)) {
            if (!this.newerVersion)
                console.log("Newer version detected " + server);
            this.newerVersion = packageInfo.version;
            if (force)
                window.location.reload();
        }
    } catch (e) {
        console.error('Error reading versions: ' + e);
    }
    return response.data;
}

function errorHandler(response) {
    if (response && response.detail) {
        let message = response.detail;
        console.log(response);
        if (response.code)
            message = `${response.code} - ${message}`;
        getNotifier().error(message, {duration: message > 150 ? 10000 : 5000});
    } else
        getNotifier().error("Hubo un error de comunicación con el servidor");
}

if (process.env.REACT_APP_BUILD === 'dev') {
    if( localStorage.host)
        config.host = localStorage.host;
}

export default config;

export const ApiContext = React.createContext(null);
