import React from 'react';
import Lottie from "lottie-react";
import animationData from './3735-loading';

const LoadingIcon = () => {

    return (
        <div className={"LoadingIcon"}>
            <Lottie animationData={animationData} />
        </div>
    );
};

export default LoadingIcon;
