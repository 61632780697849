
import {lazy} from "react";

export const mainPaths={
    login:'/login',
    register:'/register',
    base: '/'
};
const Login = lazy(() => import(/* webpackChunkName: "login" */"../../scenes/Public/Login/Login"));
const Layout = lazy(() => import(/* webpackChunkName: "layout" */ "../../scenes/Layout/Layout"));
const getMainRoutes =(loggedIn)=>{

    //Not logged in
    if(!loggedIn)
        return [
            {path:mainPaths.login, component: Login},
        ];

    else return [
        {path:mainPaths.base, component: Layout, exact: false}
    ];
};


export default getMainRoutes;
