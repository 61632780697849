import moment from 'moment';

export const standardFormat = 'DD MMM YYYY';

export const customizeMoment = ()=>{
    moment.updateLocale('es', {
        monthsShort : [
            'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
        ]
    });
};

export const weekDays = [ 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo' ];

export const enumerateDaysBetweenDates = function(startDate, endDate) {
    const dates = [];

    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');

    while(currDate.diff(lastDate) <= 0) {
        dates.push(currDate.clone());
        currDate.add(1, 'days');
    }

    return dates;
};

export const enumerateMonthsSince = (since)=>{
    const sinceM = moment(since).date(15);
    const months = [];

    const today = moment().date(20);
    while( sinceM.isBefore(today) ){
        months.push(sinceM.clone());
        sinceM.add(1, 'month');
    }

    return months;
}

export const integerTimeToString = ( time )=>{
    if( typeof time === 'undefined' || time === null )
        return '-';
    const hour=Math.floor(time/60);
    const minute = time%60;
    return `${hour<10?0:''}${hour}:${minute<10?0:''}${minute}`;
};
