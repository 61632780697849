import React from 'react';
import LoadingIcon from "../LoadingIcon/LoadingIcon";

const Splash=()=>{
    return (
        <div className='Splash'>
            <img src='/logo.png' alt='Logo' className='splash-logo'/>
            <LoadingIcon/>
        </div>
    );
};

export default Splash;
